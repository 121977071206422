<template>
  <div class="flex-box">
    <div class="pw-player-button pw-duration" v-html="durationString.value" :style="{ width: durationWidth }"></div>
    <div class="pw-player-seekbar">
      <input
        type="range"
        step="any"
        min="0"
        max="100"
        :aria-label="`${translateLabels('Seek')}`"
        ref="shakaSeeker"
        :style="seekbarCss.value"
      />
    </div>
    <div class="pw-player-button pw-duration" ref="totalDurationElm" v-html="totalDurationString.value"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, Ref } from "vue"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
export default defineComponent({
  props: {
    durationString: {
      type: Object as PropType<Ref<String>>,
      required: true
    },
    totalDurationString: {
      type: Object as PropType<Ref<String>>,
      required: true
    },
    seekbarCss: {
      type: Object as PropType<Ref<Object>>,
      required: true
    },
    pause: { type: Function, required: true },
    play: { type: Function, required: true },
    video: {
      type: Object as PropType<Ref<HTMLVideoElement | undefined>>,
      required: true
    },
    dur: {
      type: Object as PropType<Ref<number>>,
      required: true
    },
    currentTime: {
      type: Object as PropType<Ref<number>>,
      required: true
    }
  },
  setup() {
    const shakaSeeker = ref<HTMLInputElement>()
    const totalDurationElm = ref<HTMLElement>()
    let durationWidth = ref(`auto !important`)
    const { translateLabels } = injectStrict(translationKey)
    return { shakaSeeker, durationWidth, totalDurationElm, translateLabels }
  },
  mounted() {
    this.seekerSetUp()
    this.durationWidth =
      this.totalDurationElm?.clientWidth ?? 0 > 0 ? `${this.totalDurationElm!.clientWidth}px !important` : `auto !important`
  },
  methods: {
    seekerSetUp() {
      if (!this.shakaSeeker) return //TODO throw or log error
      var self = this
      this.shakaSeeker.onmousedown = function () {
        self.pause()
      }
      this.shakaSeeker.onmouseup = function () {
        var value: number = parseInt((self.shakaSeeker as HTMLInputElement).value ?? "0")
        self.video!.value!.currentTime = (value / 100) * self.dur.value
        self.play()
      }
      this.shakaSeeker.oninput = function () {
        var value: number = (parseInt((self.shakaSeeker as HTMLInputElement).value ?? "0") / 100) * self.dur.value
        self.currentTime.value = value
      }
      //Hack for Ios seeking
      this.video!.value!.onseeked = function () {
        self.video!.value!.playbackRate = 1
      }
    }
  }
})
</script>
